<script setup>
import SectionBase from '@/components/project/explore/SectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';

const props = defineProps({
  projectId: { type: Number, required: true },
  tagIds: { type: Array, default: () => [] },
});

const { fetchSimilarProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() => fetchSimilarProjects(props.tagIds, props.projectId));
</script>

<template>
  <SectionBase
    label="similar"
    :loading
    :projects
    title="同じタグのプロジェクト"
  />
</template>
