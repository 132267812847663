<script setup>
import SectionBase from '@/components/project/explore/SectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { SECTION_TITLE } from '@/consts/recommend';

const props = defineProps({
  projectId: { type: Number, required: true },
});

const { fetchRecommendedProjects, loading, projects, userId } =
  useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() => {
  fetchRecommendedProjects({
    projectId: props.projectId,
  });
});
</script>

<template>
  <SectionBase label="recommend" :loading :projects :title="SECTION_TITLE">
    <input id="recommend_user_id" type="hidden" :value="userId" />
  </SectionBase>
</template>
