import { toValue } from 'vue';
import RANKING_CATEGORIES from '@/consts/ranking';
import useTag from '@/composables/common/tag';

export default function useRanking() {
  const { fetchLayerTags, topTags, retrieveTopTagId } = useTag();

  /**
   * タグの配列から最も多い大タグのIDを返す
   * @param {number[]} tagIds
   * @returns {number|null}
   */
  const getMostFrequentTopTagId = async tagIds => {
    // タグレイヤーの取得完了を待機
    await fetchLayerTags();

    // tagIdsからそれぞれ大タグを取得して集計
    const idCounts = tagIds.reduce((acc, tagId) => {
      const id = retrieveTopTagId(tagId);
      if (id) {
        return {
          ...acc,
          [id]: (acc[id] || 0) + 1,
        };
      }
      return acc;
    }, {});

    // 最も多いタグID（小さいID優先）を取得
    const mostFrequentId = Object.keys(idCounts).reduce((acc, id) => {
      if (
        idCounts[id] > (idCounts[acc] || 0) ||
        (idCounts[id] === idCounts[acc] && Number(id) < Number(acc))
      ) {
        return id;
      }
      return acc;
    }, undefined);

    return mostFrequentId ? Number(mostFrequentId) : null;
  };

  /**
   * ランキング用のカテゴリーバリューを返す
   * 該当しないタグの場合は「総合」を返す
   * @param {number} tagId 大タグ
   * @returns {string}
   */
  const getRankingCategoryFromTagId = async tagId => {
    // タグレイヤーの取得完了を待機
    await fetchLayerTags();

    const topTag = toValue(topTags).find(
      tag => Number(tag.id) === Number(tagId),
    );
    const tagGroup = RANKING_CATEGORIES.find(
      category => category.label === topTag?.name,
    );

    return tagGroup ? tagGroup.value : RANKING_CATEGORIES[0].value;
  };

  return {
    getMostFrequentTopTagId,
    getRankingCategoryFromTagId,
  };
}
