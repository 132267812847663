<script setup>
import { ref } from 'vue';
import ProjectItem from '@/components/common/ProjectItem';
import ScrollContainer from '@/components/common/ScrollContainer';
import SectionHeader from '@/components/project/explore/SectionHeader';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

defineProps({
  containerTag: { type: String, default: 'ul' },
  href: { type: String },
  projects: { type: Array },
  label: { type: String },
  loading: { type: Boolean, default: false },
  show: { type: Boolean, default: false },
  title: { type: String },
});

const container = ref(null);
</script>

<template>
  <section
    v-if="show || loading || projects.length"
    :class="`${label}-section`"
  >
    <SectionHeader :href :label :scroll-container="container" :title>
      <slot v-if="$slots[`title.append`]" name="title.append" />
    </SectionHeader>
    <ScrollContainer
      ref="container"
      class="gap-3 sm:gap-6 -mb-6 pb-6"
      :tag="containerTag"
    >
      <template v-if="loading">
        <li
          v-for="key in PROJECT_DISPLAY_NUMBER"
          :key
          class="flex-none min-w-56 max-w-96"
        >
          <ProjectItem loading />
        </li>
      </template>
      <li
        v-for="(project, index) in projects"
        v-else
        :key="project.id"
        class="flex-none min-w-56 max-w-96"
      >
        <ProjectItem
          :coming-soon="project.is_coming_soon"
          :index
          :project
          :tracking-id="label"
        >
          <template #[`thumbnail.append`]>
            <slot name="project.thumbnail.append" :project />
          </template>
        </ProjectItem>
      </li>
    </ScrollContainer>
  </section>
</template>

<style lang="scss" scoped>
$gap: 1.5rem;

.scroll-container {
  container-type: inline-size;
}

.scroll-container > * {
  width: calc(100% / 3 - $gap * 2 / 3);

  @container (min-width: 1280px) {
    width: calc(100% / 4 - $gap * 3 / 4);
  }

  @container (min-width: 1600px) {
    width: calc(100% / 5 - $gap * 4 / 5);
  }

  @container (min-width: 1920px) {
    width: calc(100% / 6 - $gap * 5 / 6);
  }
}
</style>
