<script setup>
import { computed } from 'vue';
import { MButton, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import useScrollContainer from '@/composables/common/scrollContainer';

const props = defineProps({
  href: { type: String },
  label: { type: String },
  scrollContainer: { type: Object },
  title: { type: String },
});

const { mdAndUp } = useDisplay();
const { sendClickEvent } = useGaEvent();

const container = computed(() => props.scrollContainer?.container);

const {
  isLeftScrollDisabled,
  isRightScrollDisabled,
  isScrollable,
  scrollToLeft,
  scrollToRight,
} = useScrollContainer(container);

const moreLinkClickElementId = computed(() => `more_${props.label}`);

const onClickMore = () => {
  sendClickEvent(moreLinkClickElementId);
};

const onClickScroller = index => {
  sendClickEvent(`scroller_${props.label}`, index);
};
</script>

<template>
  <header class="section-header flex items-center gap-4 md:gap-6 mb-4 md:mb-6">
    <slot name="title">
      <MText
        class="break-phrase"
        md="3x-large"
        size="x-large"
        tag="h2"
        weight="bold"
      >
        {{ title }}
      </MText>
    </slot>
    <slot />
    <div class="grow -mx-2 md:-mx-3" />
    <template v-if="href">
      <MButton
        v-if="!mdAndUp"
        aria-label="すべて見る"
        class="-m-3 shrink-0"
        :href="href"
        icon="right"
        variant="plain"
        @click="onClickMore"
      />
      <MText
        v-else
        class="shrink-0"
        :href
        tag="span"
        weight="bold"
        @click="onClickMore"
        >すべて見る</MText
      >
    </template>
    <template v-if="isScrollable && mdAndUp">
      <MButton
        aria-label="左にスクロール"
        class="scroll-button -m-3 -ml-1"
        :disabled="isLeftScrollDisabled"
        icon="leftCircle"
        size="large"
        variant="plain"
        @click="scrollToLeft"
        @click.capture="onClickScroller(0)"
      />
      <MButton
        aria-label="右にスクロール"
        class="scroll-button -m-3 -mr-1"
        :disabled="isRightScrollDisabled"
        icon="rightCircle"
        size="large"
        variant="plain"
        @click="scrollToRight"
        @click.capture="onClickScroller(1)"
      />
    </template>
  </header>
</template>

<style scoped>
.scroll-button {
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.break-phrase {
  word-break: auto-phrase;
}
</style>
