$(() => {
  window.Geenie.Watched = {
    cookie_name: 'watched',
    set_cookie() {
      const key = this.cookie_name;
      const currentProjectId = $('#project_id').val();
      if (!currentProjectId) {
        return;
      }
      const maxProjectLength = 20;
      const options = { path: '/', expires: 90 };
      let cookies = $.cookie(key);
      if (!cookies || cookies == 'undefined') {
        $.cookie(key, currentProjectId, options);
      } else {
        cookies = cookies.split(',');
        if (cookies.length > 0) {
          for (let i = 0; i < cookies.length; i += 1) {
            const cookie = cookies[i];
            if (currentProjectId == cookie) {
              cookies.splice(i, 1);
            }
          }
          cookies.unshift(currentProjectId);
          if (cookies.length > maxProjectLength) {
            cookies.pop();
          }
          $.cookie(key, cookies.join(','), options);
        } else {
          $.cookie(key, currentProjectId, options);
        }
      }
    },
  };
});
