import { createApp } from 'vue';
import TheExploreSection from '@/components/project/explore/TheExploreSection';
import '@ca-crowdfunding/makuake-ui-n/style.css';

const mountId = 'vue-project-explore';
const mountElement = document.getElementById(mountId);

/** サーバーサイドでセットするプロパティ */
const projectId = Number(mountElement?.dataset?.projectId);
const tagIds = mountElement?.dataset?.tagIds;

const app = createApp(TheExploreSection, { projectId, tagIds });
app.mount(`#${mountId}`);
